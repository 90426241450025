<template>
  <!--
    The view for the InstallationChangelogList-component.
    Displays all installation changelogs.
  -->
  <Portlet
    title="Changelog"
    class="changelog"
    icon="clock"
  >
    <template slot="buttons">
      <button
        class="btn btn-sm btn-primary float-right"
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>{{ $t('add') }}</span>
      </button>
    </template>
    <InstallationChangelogList 
      ref="list"
      :installation-id="installationId"
    />
    <DatabaseAuditLogList
      v-if="(authenticationHasRole('scope_staff')) && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
      ref="databaseAuditLogList"
      :installation-id="installationId"
      :is-installation-changelog="true"
      @reload="reload"
    />
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationChangelogView",
  components: {
    InstallationChangelogList: () => import('@/components/Installation/InstallationChangelogList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.list.showSidebarAdd();
    },
    reload () {
      this.$refs.list.getInstallationChangelogs();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
  }
}
</script>